import { Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";

export const DealerName = styled.p`
  font: normal normal 500 24px/26px Montserrat;
  letter-spacing: 0px;
  color: #404040;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const DealerAddress = styled.p`
  font: normal normal normal 14px/16px Montserrat;
  letter-spacing: 0px;
  color: #404040;
`;

export const Dot = styled.div`
  background: #404040 0% 0% no-repeat padding-box;
  width: 4px;
  height: 4px;
`;

export const DealerType = styled.p`
  font: normal normal normal 14px/16px Montserrat;
  letter-spacing: 0px;
  color: #404040;
`;

export const Chip = styled.div`
  background: #0062aa 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 6px 21px;
`;

export const ChipText = styled.p`
  text-align: center;
  font: normal normal bold 14px/16px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
`;

export const AllOpinions = styled.p`
  font: normal normal 500 15px/20px Montserrat;
  letter-spacing: 0px;
  color: #0062aa;
  cursor: pointer;
`;

export const CustomCheckBoxInput = styled(Form.Check.Input)`
  width: 23px;
  height: 23px;
  border: 2px solid #404040;
  opacity: 1;
`;

export const CustomCheckBoxLabel = styled(Form.Check.Label)`
  font: normal normal normal 16px/32px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  cursor: pointer;
`;

export const Card = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #0000001a;
  border-radius: 15px;
`;

export const ConsultantsRow = styled(Row)`
  background: #f6a335 0% 0% no-repeat padding-box;
  height: 47px;
`;

export const ConsultantsTitle = styled.p`
  font: normal normal 500 16px/16px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
`;

export const ImageCol = styled(Col)<ImageProps>`
  min-height: 250px;
  width: 100%;
  background: transparent;
  border-radius: 15px 15px 0px 0px;
  min-width: 300px;
  height: 100%;
  @media only screen and (min-width: 768px) {
    width: 406px;
    border-radius: ${(props) =>
      props.haveworkers ? "15px 0px 0px 0px;" : "15px 0px 0px 15px;"};
  }
`;

export const Image = styled.img<ImageProps>`
  height: 300px;
  width: 100%;
  border-radius: 15px 15px 0px 0px;
  @media only screen and (min-width: 768px) {
    border-radius: ${(props) =>
      props.haveworkers ? "15px 0px 0px 0px;" : "15px 0px 0px 15px;"};
  }
`;


interface ImageProps {
  haveworkers: boolean;
}

export const WorkerOpinions = styled.span`
  font: normal normal 500 14px/15px Montserrat;
  letter-spacing: 0px;
  color: #0062aa;
  cursor: pointer;
`;
export const WorkerRating = styled.p`
  font: normal normal 500 12px/16px Montserrat;
  letter-spacing: 0px;
  color: #404040;
`;
export const WorkerStar = styled.img`
  height: 10px;
`;

export const ShowMoreWorkers = styled.p`
  text-align: right;
  font: normal normal 500 15px/15px Montserrat;
  letter-spacing: 0px;
  color: #404040;
  cursor: pointer;
`;

export const DealerInfo = styled(Col)<DealerInfoProps>`
    border-radius:  ${(props) =>
      props.haveworkers ? "15px 15px 0px 0px;" : "15px;"};
    margin-top: -20px;
    background-color: #FFFFFF;
    min-width: 300px;
    @media only screen and (min-width: 768px) { 
        margin-top: 0;
        border-radius:  ${(props) =>
          !props.paid ? "15px;" : props.haveworkers ? "0px 15px 0px 0px;" : "0px 15px 15px 0px;"}
    }
`;

interface DealerInfoProps {
  haveworkers: boolean;
  paid: boolean;
}

export const AllWorkers = styled(Row)`
  background: #f3f3f3 0% 0% no-repeat padding-box;
`;

export const ShowMore = styled(Row)`
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 15px 15px;
  padding: 10px 0;
`;

export const AllOpinionsArrow = styled.div`
  border: 2px solid #0062aa;
  width: 10px;
  height: 10px;
  transition: transform 0.5s;
  transform: rotate(-45deg);
  border-width: 0 2px 2px 0;
  cursor: pointer;
  margin-top: 5px;
`;

export const WorkerOpinionsArrow = styled.div`
  border: 2px solid #0062aa;
  width: 8px;
  height: 8px;
  display: inline-block;
  transition: transform 0.5s;
  transform: rotate(-45deg);
  padding: 3px;
  border-width: 0 2px 2px 0;
  cursor: pointer;
`;

export const ShowMoreWorkersArrow = styled.div`
  border: 2px solid #404040;
  display: inline-block;
  transition: transform 0.5s;
  transform: rotate(45deg);
  border-width: 0 2px 2px 0;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 5px;
`;

export const ShowLessWorkersArrow = styled.div`
  border: 2px solid #404040;
  display: inline-block;
  transition: transform 0.5s;
  transform: rotate(-135deg);
  border-width: 0 2px 2px 0;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 2px;
`;

export const WorkerName = styled.p`
  max-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;
