import styled from "styled-components";

export const SliderTrack = styled.div`
  width: 250px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #404040;
  border-radius: 10px;
  opacity: 1;
  position: relative;
`;
export const Slider = styled.div`
  width: 122px;
  height: 36px;
  background: var(--unnamed-color-0062aa) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-0062aa);
  background: #0062aa 0% 0% no-repeat padding-box;
  border: 1px solid #0062aa;
  border-radius: 10px;
  opacity: 1;
  position: relative;
  z-index: 2;
  margin: -1px;
  cursor: pointer;
`;
export const LeftText = styled.div`
height: 35px;
display: flex;
align-items: center;
  text-align: center;
  vertical-align: middle;
  line-height: 35px !important;
  font: 12px Montserrat;
  letter-spacing: 0px;
  color: #404040;
  opacity: 1;
  height: 18px;
  position: absolute;
  top: 5px;
  left: 23px;
`;
export const RightText = styled.div`
height: 35px;
display: flex;
align-items: center;
  text-align: center;
  vertical-align: middle;
  line-height: 35px !important;
  font: 12px Montserrat;
  letter-spacing: 0px;
  color: #404040;
  opacity: 1;
  height: 18px;
  position: absolute;
  top: 5px;
  right: 21px;
`;

export const SliderText = styled.div`
height: 35px;
display: flex;
align-items: center;
justify-content: center;
  text-align: center;
  vertical-align: middle;
  line-height: 35px !important;
  font: 12px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
`;
