import React, {
  MouseEvent,
  TouchEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  SliderText,
  LeftText,
  Slider,
  SliderTrack,
  RightText,
} from "src/scripts/components/rangeslider/Styled";

interface Props {
  minValue: number;
  maxValue: number;
  unit: string;
  step: number;
  value: number;
  style?: { [key: string]: any };
  setValue: (value: number) => void;
  prefix?: string;
}

const RangeSlider = (props: Props) => {
  const {
    unit,
    minValue,
    maxValue,
    step,
    value,
    setValue,
    style = {},
    prefix = "",
  } = props;
  const [knobOffset, setKnobOffset] = useState<number>(-1);
  const [trackOffsetLeft, setTrackOffsetLeft] = useState<number>(0);
  const [clickHeight, setClickHeight] = useState<number>(-1);
  const [dragging, setDragging] = useState<boolean>(false);
  const track = useRef<HTMLDivElement>(null);
  const knob = useRef<HTMLDivElement>(null);
  const innerText = useRef<HTMLParagraphElement>(null);
  useEffect(() => {
    setTrackOffsetLeft(getOffsetLeft(track.current) + knob.current!.offsetWidth / 2);
  },[])
  function getOffsetLeft( elem:any )
  {
      var offsetLeft = 0;
      do {
        if ( !isNaN( elem.offsetLeft ) )
        {
            offsetLeft += elem.offsetLeft;
        }
      } while( elem = elem.offsetParent );
      return offsetLeft;
  }
  const onMouseDown = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onDragStart(e.clientX, e.clientY);
  };
  const onTouchStart = (e: TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onDragStart(e.touches[0].clientX, e.touches[0].clientY);
  };
  const onDragStart = (clientX: number, clientY:number) => {
    if (knob.current) {
      setKnobOffset(clientX - knob.current.offsetLeft);
      setClickHeight(clientY)
      setDragging(true);
    }
  };
  useEffect(() => {
    if (knob.current && track.current) {
      let trackWidth = track.current.offsetWidth;
      let knobWidth = knob.current.offsetWidth;
      let maxRight = trackWidth - knobWidth;
      const toSet = maxRight * ((value - minValue) / (maxValue - minValue));
      if (toSet >= maxRight) {
        knob.current.style.left = maxRight + "px";
      } else if (toSet <= 0) {
        knob.current.style.left = 0 + "px";
      } else {
        knob.current.style.left = toSet + "px";
      }
    }
  }, []);
  const onDrag = (clientX: number, e?:any) => {
    if (track.current && knob.current && innerText.current) {
      let trackWidth = track.current.offsetWidth;
      let knobWidth = knob.current.offsetWidth;
      let maxRight = trackWidth - knobWidth;
      let trackStart = trackOffsetLeft;
      if (dragging && knob.current && innerText.current) {
        let shift = clientX - trackStart;
        if (shift < 0) {
          shift = 0;
        } else if (shift > maxRight) {
          shift = maxRight;
        }
        let offset = shift
        let percent = shift / maxRight;
        const current = minValue + (maxValue - minValue) * percent;
        if (Math.abs(current - value) >= step && !(value<minValue) && !(value>maxValue)){
          const steps = Math.abs(Math.floor((current-value)/step)) * step;
          setLocationAndValue(offset + "px",current > value ? value + steps : value - steps)
        }
      }
    }
  };
  const setLocationAndValue =  (location:string,value:number)=>{
    knob.current!.style.left = location;
    setValue(value);
  }
  const onTouchMove = (e: TouchEvent) => {
    e.stopPropagation();
    onDrag(e.touches[0].clientX);
  };
  const onMouseMove = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onDrag(e.clientX);
  };
  const onMouseOut = (e: MouseEvent) => {
    let trackHeight = track.current?.clientHeight
    let clickStart = clickHeight;
    let clickEnd = e.clientY;
      if (Math.abs(clickStart - clickEnd) > trackHeight!/2) {
      return
    }
    if (track.current && knob.current && innerText.current) {
      let trackWidth = track.current.offsetWidth;
      let knobWidth = knob.current.offsetWidth;
      let maxRight = trackWidth - knobWidth;
      if (knob.current && innerText.current) {
        let offset = e.clientX - knobOffset;
        if (offset < 0) {
          offset = 0;
        } else if (offset > maxRight) {
          offset = maxRight;
        }
        let percent = offset / maxRight;
        const current = minValue + (maxValue - minValue) * percent;
        if (Math.abs(current - value) >= step) {
          setLocationAndValue(offset + "px",offset > 0 ? maxValue : minValue)
        }
      }
    }
  }
  return (
    <SliderTrack
      ref={track}
      onDrag={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      style={style}
    >
      <LeftText>{`${minValue.toFixed(0)} ${unit}`}</LeftText>
      <Slider
        ref={knob}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        onMouseUp={(e) => {
          setDragging(false);
        }}
        onClick={(e) => {
          setDragging(true);
          onMouseMove(e);
          setDragging(false);
        }}
        onMouseLeave={(e) => {
          if(dragging){
            onMouseOut(e);
          }
          setDragging(false)
        }}
        onMouseOverCapture={(e) => {
          if(dragging){
            onMouseMove(e)
          }}}
        onMouseOut={(e) => {
          if(dragging){
            onMouseOut(e);
          }
          setDragging(false);
        }}
        onTouchEnd={() => setDragging(false)}
        onMouseMove={onMouseMove}
        onTouchMove={onTouchMove}
      >
        <SliderText ref={innerText} onMouseMove={(e) => e.preventDefault()}>
          {`${prefix} ${value.toFixed(0)} ${unit}`}
        </SliderText>
      </Slider>
      <RightText>
        <div>{`${`${maxValue.toFixed(0)}`} ${unit}`}</div>
      </RightText>
    </SliderTrack>
  );
};
export default RangeSlider;
