import React, { useEffect, useState } from "react";
import DropdownSelect from "src/scripts/components/common/DropdownSelect";
import ReactDOM from "react-dom";
import { Button, Col, Row, Form } from "react-bootstrap";
import styled from "styled-components";
import { Voivodeship } from "../../models/Voivodeship";
import { VoivodeshipsList } from "../../static-data/Voivodeships";

export interface Brand {
  id: number;
  name: string;
  icon: string;
  counter: number;
}

const StyledButton = styled(Button)`
  padding-left: 10%;
  padding-right: 10%;
  &:hover {
    color: #fff;
    background-color: #5bccf6;;
    border-color: #fff;
  }
  margin: 10px;
  @media screen and (min-width: 469px) {
    margin: 0;
    margin-left: 10px;
  }
`;

const StyledH2 = styled.h2`
  font: normal normal bold 18px/20px Montserrat, serif;
  letter-spacing: 0;
  color: #ffff;
  opacity: 1;
  margin: auto;

  @media screen and (min-width: 768px) {
    font: normal normal bold 18px/34px Montserrat, serif;
  }
`;

const StyledH3 = styled.h3`
  font: normal normal bold 20px/24px Montserrat, serif;
  letter-spacing: 0;
  color: #ffff;
  opacity: 1;
  margin: auto;
  color: #030e12;
  white-space: break-spaces;
  text-align: center;

  @media screen and (min-width: 768px) {
    font: normal normal bold 28px/34px Montserrat, serif;
  }
`;


const StyledColumn = styled(Col)`
  background: #404040 0 0 no-repeat padding-box;
  opacity: 1;
  height: 54px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const CustomCheckBoxInput = styled(Form.Check.Input)`
  width: 23px;
  height: 23px;
  border: 2px solid #404040;
  opacity: 1;
`;

const CustomCheckBoxLabel = styled(Form.Check.Label)`
  text-align: left;
  font: normal normal normal 12px/18px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    font: normal normal normal 18px/28px Montserrat;
  }
`;

const SearchOpinionsForm = () => {
  const [voivodeships, setVoivodeships] = useState<Voivodeship[]>(VoivodeshipsList);
  const [selectedVoivodeships, setSelectedVoivodeships] = useState<Voivodeship[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<Brand[]>([]);
  const [onlyVerified, setOnlyVerified] = useState<boolean>(false);

  const search = () => {
    window.location.href = `/search/` + generateSearchQuerry();
  }

  const generateSearchQuerry = () => {

    const dealerVerified = onlyVerified ? "certified=true" : "";
    const voivodeshipsQuerry =
      selectedVoivodeships.length !== 0
        ? `voivodeship=${selectedVoivodeships.map(value => value.name).join(",")}&`
        : "";
    const brandsQuerry =
        selectedBrands.length !== 0 && selectedBrands.length !== 1
        ? `brand=${selectedBrands.map(value => value.name).join(",")}&`
        : "";
    const singleBrand = selectedBrands.length === 1 ? `${selectedBrands[0].name}/` : "";

    return `${singleBrand}?${brandsQuerry}${voivodeshipsQuerry}${dealerVerified}`;
  };

  const modifyUrl = (type: string) => {
    const newurl = window.location.href.replace(/service|salon/g, type)
    window.history.pushState({path:newurl},'',newurl);
  }

  return (
    <div className="container">
      <Row style={{margin: 0}}>
        <StyledColumn
          className="d-flex justify-content-center"
          style={{background: '#5bccf6 0 0 no-repeat padding-box'}}>
          <StyledH2
            style={{color: '#030e12'}}
            >
            Wyszukaj serwis
          </StyledH2>
        </StyledColumn>
      </Row>
      <Row className="d-flex justify-content-center m-2">
          <DropdownSelect
            options={voivodeships}
            selected={selectedVoivodeships}
            setSelected={setSelectedVoivodeships}
            placeholder="Województwo"
          />
          <StyledButton
            variant="primary"
            className="d-flex align-items-center button-primary"
            onClick={() => search()}>
            <span>Szukaj</span>
          </StyledButton>
      </Row>
      <Row>
        <StyledH3>
            lub zadzwoń
            {"\n"}
            <a style={{color: '#030e12'}} href="tel:725704112">
            725 704 112
            </a>
        </StyledH3>
      </Row>
    </div>
  );
};
const opinionsHomepageFormRoot = document.getElementById(
  "react--opinions-homepage-form"
);
if (opinionsHomepageFormRoot) {
  ReactDOM.render(<SearchOpinionsForm />, opinionsHomepageFormRoot);
}
